body {
	font-family: "Lato", sans-serif;
}

.form-group {
	margin-bottom: 1rem;
	width: 100%;
}

.form-control {
	width:100%;
}

.main-head {
	height: 150px;
	background: #fff;
}

.reglink {
  color: white;
}

.reglink:visited{
  color: white;
}

.sidenav {
	height: 100%;
	background-color: #0a3152;
	overflow-x: hidden;
	/* padding-top: 20px; */
}

.main {
	padding: 0px 10px;
}

@media screen and (max-height: 450px) {
	.sidenav {
		padding-top: 15px;
	}
}

@media screen and (max-width: 450px) {
	.login-form {
		margin-top: 20%;
	}

	.register-form {
		margin-top: 10%;
	}
}

@media screen and (min-width: 880px) {
	.sidenav {
		width: 40%;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
	}

	.login-form {
		margin: auto;
	}

	.register-form {
		margin-top: 30%;
	}
}

.login-main-text {
	margin-top: 2%;
	padding: 1px;
	color: #fff;
	text-align: center;
}

.login-main-text h2 {
	font-weight: 300;
}

.btn-black {
	background-color: #82b0c9 !important;
	color: #fff;
}

/* registration.jsx */
.btn-warning {
	margin-right: 15%;
	margin-top: 1rem;
}
/* end of registration.jsx */

.stannington-carnival-sidebar {
	margin-left: 25%;
	margin-top: 0%;
}

.btn-container {
	display: flex;
	width: 100%;
	gap: 0.5rem;
	justify-content: center;
}
