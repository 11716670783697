.navbar {
	overflow: hidden;
	display: flex; 
	/* height: 6rem; */
	width: 100%;
	background-color: #0a3051;
	position: relative; 
}

.nav-buttons {
	display: flex;
	margin-left: auto;
	margin-right: 2rem;
}

.nav-btn {
	border: none;
	width: 100px;
	height: 100%;
	background-color: #0a3051;
	padding: 10px 10px 10px 10px;
	font-size: 20px;
	margin-right: auto;
	cursor: pointer;
	color: white;
}

.navicon {
	border: none;
	width: 100px;
	height: 100%;
	background-color: #0a3051;
	padding: 10px 10px 10px 10px;
	font-size: 20px;
	margin-left: auto;
	cursor: pointer;
	color: white;
	display: none;
}

.nav-btn:hover {
	color: lightblue;
}

@media screen and (max-width: 800px) {
  .navbar .nav-buttons {display: none;}
  .navbar .navicon {
    float: right;
    display: block;
  }
	.navbar {
		position: sticky; 
		top:0;
	}
	
}

@media screen and (max-width: 800px) {
  .navbar.responsive {position: relative;}
  .navbar.responsive .nav-buttons {
    float: none;
    display: flex;
		flex-direction: column;
    text-align: left;
		position: sticky; 
		top:0;
  }
	.navbar.responsive {
		position: sticky; 
		top:0;
	}
}