.user-list {
	border-radius: 20px;
	border: 1px solid #aaa;
	overflow: hidden;
}

.user-list p {
	margin: 0.5rem;
}

.user-li {
	padding-inline: 0.5rem;
	border-top: 1px solid #aaa;
	border-bottom: 1px solid #aaa;
}

.user-li:nth-child(n + 2) {
	/* Prevent double border */
	margin-top: -1px;
}

.user-li:nth-child(2n - 1) {
	background-color: #eee;
}

.user-li:first-child {
	border-top: none;
}
.user-li:last-child {
	border-bottom: none;
}

.cool-search {
	margin: 0.7rem 1rem 0rem 1rem;
	border-radius: 30px;
	font-size: 15px;
	padding: 0.6rem 1rem;
	border: 2px solid #00bfff71;
}

.cool-search:focus {
	outline: none;
	border: 2px solid #0092c371;
}
