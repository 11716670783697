.selected {
	box-shadow: 0px 0px 15px #1c81bb !important;
}

.booking-card {
	border-radius: 12px;
	padding: 1.5rem;
	transition: all 0.2s ease-in-out;
	width: 425px;
	height: 460px;
	box-shadow: 0px 0px 10px 0px #aaa;
}

.booking-card:hover {
	transform: scale(103%);
}

.card-bold {
	font-size: 25px;
	font-weight: bold;
	font-family: "Barlow Semi Condensed", sans-serif;
}

.card-type {
	align-items: center;
	padding: 0.3rem 0.6rem;
	border-radius: 99px;
	color: white;
	gap: 0.5rem;
}

.contact-info-container {
	display: flex;
	align-items: center;
}

.card-contact-header {
	align-items: center;
	gap: 0.5rem;
	margin: 1rem 0rem;
	font-weight: bold;
}

.card-contact-info p {
	margin: 0;
}

.stall-type {
	width: min-content;
	padding-inline: "1rem";
}

.pointer:hover {
	cursor: pointer;
}

.icon:hover {
	gap: 0.5rem;
}

.icon-label {
	max-width: 0;
	opacity: 0;
	transition: 0.2s ease-in-out;
}

.icon:hover .icon-label {
	max-width: 100px;
	opacity: 1;
}

.card-comments {
	overflow-y: auto;
	height: 5.2rem;
	border: 1px solid #ddd;
	border-radius: 12px;
	padding: 0.5rem;
	margin: 0.5rem 0rem 0rem 0rem;
	background-color: #fafafa;
}

.warning {
	color: crimson;
}
