.home-header {
	background-color: lightblue;
	padding: 3rem;
	background: rgb(95, 165, 226);
	background: linear-gradient(
		309deg,
		rgba(95, 165, 226, 1) 0%,
		rgba(155, 216, 236, 1) 100%
	);
}

.hero-banner {
	box-sizing: border-box;
	border-radius: 16px;
	/* background: linear-gradient(
		309deg,
		rgb(66, 135, 195) 0%,
		rgb(100, 169, 225) 100%
	); */
	height: fit-content;
	width: calc(75% - 4rem);
	padding: 2rem;
}

.hero-image {
	height: 355px;
	border-radius: 8px;
	margin-right: 2rem;
}

.newhr {
	margin-inline: 2rem;
	border: none;
	border-top: 1px solid #eee;
	margin-top: 1rem;
}

.showcase {
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 2rem;
}

.home-footer {
	color: white;
	height: 250px;
	background-color: #0a3051;
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer-social {
	color: white;
	height: 50px;
	width: 50px;
}
