@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;500&family=Nunito:wght@500&display=swap");

body {
	margin: 0;
	font-family: "nunito", -apple-system, BlinkMacSystemFont, circular std,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #eee;
	border-radius: 90px;
}

::-webkit-scrollbar-thumb {
	background: #666;
	border-radius: 90px;
}

::-webkit-scrollbar-thumb:hover {
	background: #333;
}

/* Global styles go in here please :) */
/* Please dont change */

.header-font {
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: bold;
}

.fb {
	display: flex;
}

.row {
	flex-direction: row;
}

.col {
	flex-direction: column;
}

.mg-0 {
	margin: 0;
}

.mg-1 {
	margin: 1rem;
}

.mg-2 {
	margin: 2rem;
}

.ml-auto {
	margin-left: auto;
}

.no-td {
	text-decoration: none;
}

.centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.card {
	border: 2px solid black;
	border-radius: 20px;
	padding: 1rem;
}

.form-control {
	padding: 0.5rem;
	border-radius: 12px;
	border: 3px solid #07243c;
	font-size: 18px;
	width: 20rem;
}

.btn {
	cursor: pointer;
	font-size: 16px;
	border: none;
	padding: 0.7rem 1rem;
	border-radius: 12px;
	transition: all 0.3s ease-in-out;
	background-color: #0a3153;
	color: white;
}

.btn:hover {
	background-color: #12518b;
}

.form-input {
	resize: vertical;
	/* Just for textarea elements */
	font-size: 15px;
	border-radius: 6px;
	border: 1px solid #555;
	padding: 0.6rem;
	width: 100%;
	font-family: Arial, Helvetica, sans-serif;
}

.auto-width {
	width: auto;
}

.red {
	background-color: #e05147;
}

.red:hover {
	background-color: #e1685f;
}

.green {
	background-color: rgb(0, 173, 0);
}

.green:hover {
	background-color: #0f8f01;
}

.orange {
	background-color: #ffa500;
}

.orange:hover {
	background-color: #ffb224;
}

.blue {
	background-color: #008cff;
}

.blue:hover {
	background-color: #42a8fb;
}

.bold {
	font-weight: bold;
}

.gap-0 {
	gap: 0;
}

.gap-1 {
	gap: 0.5rem;
}

.gap-2 {
	gap: 1rem;
}

.gap-3 {
	gap: 1.5rem;
}

.gap-4 {
	gap: 2rem;
}

.gap-5 {
	gap: 2.5rem;
}

.gap-6 {
	gap: 3rem;
}

.mt-1 {
	margin-top: 1rem;
}
.mt-2 {
	margin-top: 2rem;
}


.flexcontainer {
  display: flex;
  flex-wrap: wrap;
	flex-direction: row;
}

.flex-item-left {
  flex: 28%;
}

.flex-item-right {
  flex: 70%;
}


@media (max-width: 800px) {
	.flexcontainer {
		flex-direction: column;
	}
  .flex-item-right, .flex-item-left {
    flex: 100%;
  }
}