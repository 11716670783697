.modal-bg {
	background: rgba(0, 0, 0, 0.65);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

.modal-fg {
	background: #fff;
	margin: 3rem auto;
	padding: 15px;
	width: 800px;
}
